import React from "react"
import SEO from "../components/seo"
import styled from "styled-components"

const Container = styled.div`
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  font-family: sans-serif;
`

const Name = styled.h1`
  font-size: 14px;
  text-transform: uppercase;
`

const Heading = styled.h1`
  color: red;
`

const PageHome = () => (
  <>
    <SEO title="Home" />
    <Container>
      <Name>Sam Featherstone</Name>
      <Heading>Web Development</Heading>
    </Container>
  </>
)

export default PageHome
